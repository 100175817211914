export default {
  data () {
    return {}
  },
  methods: {
    getSubType (state) { // 获取代码提交状态-文本
      switch (state) {
        case 0:
          return '等待'
        case 1:
          return '等待重判'
        case 2:
          return '编译中'
        case 3:
          return '运行并评判'
        case 4:
          return '正确'
        case 5:
          return '格式错误'
        case 6:
          return '答案错误'
        case 7:
          return '时间超限'
        case 8:
          return '内存超限'
        case 9:
          return '输出超限'
        case 10:
          return '运行错误'
        case 11:
          return '编译错误'
        case 12:
          return '编译成功'
        case 13:
          return '运行完成'
        default:
          return '未知状态'
      }
    },
    getStateColor (state) { // 获取代码提交状态-文本颜色
      switch (state) {
        case 4:
          return '#4DA54D'
        case 12:
          return '#4DA54D'
        case 13:
          return '#4DA54D'
        case 5:
          return '#CE433F'
        case 6:
          return '#CE433F'
        case 10:
          return '#CE433F'
        case 11:
          return '#CE433F'
        default:
          return '#EB9316'
      }
    },
    // 获取语言
    getLanText(state) {
      switch (state) {
        case 0: {
          return "C";
        }
        case 1: {
          return "C++";
        }
        case 2: {
          return "Pascal";
        }
        case 3: {
          return "Java";
        }
        case 4: {
          return "Ruby";
        }
        case 5: {
          return "Bash";
        }
        case 6: {
          return "Python";
        }
        case 7: {
          return "php";
        }
        case 8: {
          return "perl";
        }
        case 9: {
          return "c#";
        }
        case 10: {
          return "objectiveC";
        }
        case 11: {
          return "freeBasic";
        }
        case 12: {
          return "scheme";
        }
        case 13: {
          return "Clang";
        }
        case 14: {
          return "Clang++";
        }
        case 15: {
          return "Lua";
        }
        case 16: {
          return "JavaScript";
        }
        case 17: {
          return "Go";
        }
      }
    },
  }
}
